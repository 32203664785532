<template>
  <v-row>
    <ExpandCollapseToggle
      id="deckPanelToggle"
      @toggleAllPanels="toggleAllPanels"
      styleClass="mt-3"
      :panel="panel"
    />
    <v-expansion-panels v-model="panel" multiple color="#fff">
      <v-expansion-panel style="margin-top: 20px">
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(0)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(0)" icon="fa-solid fa-minus" />
          <h3>Deck Conditions</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text eager>
          <v-form v-model="valid" ref="inspectionDeckForm">
            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="deckCondRating"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      deckValue.DK_DURATION,
                      deckValue.DK_DURATION_CHANGES
                    )
                  "
                  label="1A01 Deck Condition Rating"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      deckConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="deckValue.DKRATING"
                ></LabelSelect
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <CommentTypeTextField
                  v-if="moduleType == MODULE_TYPES.INSPECTION"
                  label="Overall Deck Notes"
                  id="field_Overall_Deck_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.OVERALL_DECK_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                />
                <ManagementCommentTypeTextField
                  v-else
                  label="Overall Deck Notes"
                  id="field_Overall_Deck_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.OVERALL_DECK_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                  :comments="deckValue.inspectionComments"
                  :brkey="deckValue.brkey"
                  :inspectionId="deckValue.inspectionId"
                  @updateComment="
                    (comment) =>
                      setUpdatedComment(
                        comment,
                        INSPECTION_COMMENTS.OVERALL_DECK_COMMENTS
                      )
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="surfaceCondRating"
                  showDurationIcon
                  :tooltip="
                    getHistDuration(
                      deckValue.DECK_WS_SURF_DURATION,
                      deckValue.DECK_WS_SURF_DURATION_CHANGES
                    )
                  "
                  label="6B40 Wearing Surface Condition Rating"
                  :refTable="REFERENCE_TABLE.CONDITION_RATING"
                  :isEditable="isEditable"
                  :isRequired="isEditable"
                  @update:modelValue="
                    {
                      surfaceConditionUpdated = true;
                      conditionUpdated = true;
                    }
                  "
                  v-model="deckValue.DECK_WS_COND_RATE"
                ></LabelSelect></v-col
            ></v-row>

            <v-row>
              <v-col cols="12">
                <CommentTypeTextField
                  v-if="moduleType == MODULE_TYPES.INSPECTION"
                  label="Overall Wearing Surface Notes"
                  id="field_Overall_Wearing_Surface_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.OVERALL_WEARING_SURFACE_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                />
                <ManagementCommentTypeTextField
                  v-else
                  label="Overall Wearing Surface Notes"
                  id="field_Overall_Wearing_Surface_Notes"
                  :inspectionCommentType="
                    INSPECTION_COMMENTS.OVERALL_WEARING_SURFACE_COMMENTS
                  "
                  :readonly="!isEditable"
                  :counter="commentsMaxLength"
                  :maxlength="commentsMaxLength"
                  :comments="deckValue.inspectionComments"
                  :brkey="deckValue.brkey"
                  :inspectionId="deckValue.inspectionId"
                  @updateComment="
                    (comment) =>
                      setUpdatedComment(
                        comment,
                        INSPECTION_COMMENTS.OVERALL_WEARING_SURFACE_COMMENTS
                      )
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="surfaceTypeMain"
                  label="5B02 Surface Type Main"
                  :refTable="REFERENCE_TABLE.DECK_SURFACE_TYPE"
                  v-model="deckValue.DKSURFTYPE"
                ></LabelSelect>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="membraneTypeMain"
                  label="5B03 Membrane Type Main"
                  :refTable="REFERENCE_TABLE.DECK_MEMBRANE_TYPE"
                  v-model="deckValue.DKMEMBTYPE"
                ></LabelSelect
              ></v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="8" md="12" sm="12" cols="12">
                <LabelSelect
                  labelXl="6"
                  labelLg="7"
                  labelMd="7"
                  labelSm="7"
                  selectXl="5"
                  selectLg="4"
                  selectMd="5"
                  selectSm="5"
                  id="protectionMain"
                  label="5B04 Protection Main"
                  :refTable="REFERENCE_TABLE.DECK_PROTECTION_TYPE"
                  v-model="deckValue.DKPROTECT"
                ></LabelSelect
              ></v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="4"
                  textMd="6"
                  textSm="5"
                  id="WearingSurfaceThicknessMain"
                  :isEditable="isEditable"
                  label="6A33 Wearing Surface Thickness Main/Approach"
                  v-model="deckValue.MAIN_WS_THICKNESS"
                  @update:modelValue="updateMainRecordedDate"
                  appendWith=" in"
                  type="number"
                  textStyle="width:90%"
                  maxlength="4"
                  decimalPlaces="1"
                  :pairCode="PAIR_CODE.MILLIMETERS_TO_INCHES"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                  :rules="
                    isEditable
                      ? [
                          (value) => {
                            return lessThanValueRule(
                              value,
                              DECK_MAX_VALUE.WEARING_SURFACE_THICKNESS
                            );
                          },
                        ]
                      : []
                  "
                >
                </LabelText></v-col
              ><v-col xl="4" lg="3" md="4" sm="4" cols="4" align-self="center">
                <LabelText
                  id="WearingSurfaceThicknessApproach"
                  :isEditable="isEditable"
                  v-model="deckValue.APPR_WS_THICKNESS"
                  @update:modelValue="updateApproachRecordedDate"
                  appendWith=" in"
                  textXl="11"
                  textLg="10"
                  textMd="10"
                  textSm="10"
                  type="number"
                  maxlength="4"
                  textStyle="width:90%"
                  decimalPlaces="1"
                  :pairCode="PAIR_CODE.MILLIMETERS_TO_INCHES"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                  :rules="
                    isEditable
                      ? [
                          (value) => {
                            return lessThanValueRule(
                              value,
                              DECK_MAX_VALUE.WEARING_SURFACE_THICKNESS
                            );
                          },
                        ]
                      : []
                  "
                >
                </LabelText>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelDatefield
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="4"
                  textMd="6"
                  textSm="5"
                  textStyle="width:90%"
                  id="WearingSurfaceRecordedDateMain"
                  label="6A34 Wearing Surface Thickness Date Recorded Main/Approach"
                  v-model="deckValue.MAIN_WS_THICK_DATE"
                  :isEditable="isEditable"
                >
                </LabelDatefield>
              </v-col>
              <v-col xl="4" lg="3" md="4" sm="4" cols="4" align-self="center">
                <LabelDatefield
                  textXl="11"
                  textLg="10"
                  textMd="10"
                  textSm="10"
                  textStyle="width:90%"
                  id="WearingSurfaceRecordedDateApproach"
                  v-model="deckValue.APPR_WS_THICK_DATE"
                  :isEditable="isEditable"
                >
                </LabelDatefield>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  id="DeckProtectInstallYear"
                  label="6A36 Protect Install Year"
                  v-model="deckValue.PROTECT_YEAR"
                  :isEditable="isEditable"
                  type="number"
                  :showComma="false"
                  :maxlength="4"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                xl="12"
                lg="12"
                md="12"
                sm="8"
                cols="12"
                align-self="center"
              >
                <LabelText
                  labelXl="4"
                  labelLg="6"
                  labelMd="4"
                  labelSm="7"
                  textXl="7"
                  textLg="5"
                  textMd="7"
                  textSm="5"
                  id="DeckProtectInstallNote"
                  :isEditable="isEditable"
                  label="6A37 Protect Install Note"
                  v-model="deckValue.PROTECT_NOTE"
                  textStyle="width:100%"
                  type="text"
                  maxlength="30"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  id="crackingMetric"
                  :isEditable="isEditable"
                  label="6B47 Deck Cracking Metric"
                  v-model="deckValue.DECK_CRACKING_METRIC"
                  appendWith=" YD/SY"
                  textStyle="width:90%"
                  :decimalPlaces="2"
                  type="number"
                  :rules="
                    isEditable ? [(greaterThanZeroRule, lessThanOneRule)] : []
                  "
                  maxlength="4"
                >
                </LabelText>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  id="maxBridgeHeight"
                  :isEditable="isEditable"
                  :isRequired="isEditable && isRequiredByGroup"
                  label="B.G.13 Maximum Bridge Height"
                  v-model="deckValue.MAX_STRUCTURE_HEIGHT"
                  appendWith=" ft"
                  textStyle="width:80%"
                  type="number"
                  maxlength="4"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.ROUND"
                >
                </LabelText>
              </v-col>
            </v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="3"
                  selectMd="4"
                  selectSm="5"
                  id="deckStrucType"
                  label="5B01 Deck Structure Type"
                  :refTable="REFERENCE_TABLE.DECK_STRUCTURE_TYPE"
                  v-model="deckValue.DKSTRUCTYP"
                ></LabelSelect>
              </v-col>
            </v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="deckWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5B07 Deck Width (O/O)"
                  v-model="deckValue.DECKWIDTH"
                  appendWith=" ft"
                  :decimalPlaces="1"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="roadwayWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5C27 Roadway Width (C/C)"
                  :model-value="deckValue.roadwayWidth"
                  appendWith=" ft"
                  :decimalPlaces="1"
                  type="number"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="leftSidewalkWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5B05 Left Curb or Sidewalk Width"
                  v-model="deckValue.LFTCURBSW"
                  appendWith=" ft"
                  textStyle="width:80%"
                  :decimalPlaces="1"
                  type="number"
                  :maxlength="5"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>
            <v-row
              ><v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelText
                  id="rightSidewalkWidth"
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  textXl="5"
                  textLg="3"
                  textMd="4"
                  textSm="5"
                  label="5B06 Right Curb or Sidewalk Width"
                  v-model="deckValue.RTCURBSW"
                  appendWith=" ft"
                  textStyle="width:80%"
                  :decimalPlaces="1"
                  type="number"
                  :maxlength="5"
                  :pairCode="PAIR_CODE.METERS_TO_FEET"
                  :roundingFactor="ROUNDING_FACTOR.TENTH"
                >
                </LabelText></v-col
            ></v-row>

            <v-row>
              <v-col xl="8" lg="9" md="8" sm="8" cols="8" align-self="center">
                <LabelSelect
                  labelXl="6"
                  labelLg="8"
                  labelMd="6"
                  labelSm="7"
                  selectXl="5"
                  selectLg="3"
                  selectMd="4"
                  selectSm="5"
                  id="deckStrucType"
                  label="5B08 Bridge Median Type"
                  :options="bridgeMedianTypes"
                  v-model="deckValue.BRIDGEMED"
                ></LabelSelect>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title
          class="d-flex justify-start"
          hide-actions
          expand-icon="fa-solid fa-plus"
          collapse-icon="fa-solid fa-minus"
        >
          <v-icon v-if="!panel.includes(1)" icon="fa-solid fa-plus" />
          <v-icon v-if="panel.includes(1)" icon="fa-solid fa-minus" />
          <h3>Deck Inspection Notes</h3>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row>
            <v-col sm="12">
              <CommentTypeTextField
                v-if="moduleType == MODULE_TYPES.INSPECTION"
                label="Deck Top and Wearing Surface"
                id="field_Deck_Top_Wearing_Surface"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.DECK_TOP_WEARING_SURFACE
                "
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
              <ManagementCommentTypeTextField
                v-else
                label="Deck Top and Wearing Surface"
                id="field_Deck_Top_Wearing_Surface"
                :inspectionCommentType="
                  INSPECTION_COMMENTS.DECK_TOP_WEARING_SURFACE
                "
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :comments="deckValue.inspectionComments"
                :brkey="deckValue.brkey"
                :inspectionId="deckValue.inspectionId"
                @updateComment="
                  (comment) =>
                    setUpdatedComment(
                      comment,
                      INSPECTION_COMMENTS.DECK_TOP_WEARING_SURFACE
                    )
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <CommentTypeTextField
                v-if="moduleType == MODULE_TYPES.INSPECTION"
                label="Deck Underside"
                id="field_Deck_Underside"
                :inspectionCommentType="INSPECTION_COMMENTS.DECK_UNDERSIDE"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
              <ManagementCommentTypeTextField
                v-else
                label="Deck Underside"
                id="field_Deck_Underside"
                :inspectionCommentType="INSPECTION_COMMENTS.DECK_UNDERSIDE"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :comments="deckValue.inspectionComments"
                :brkey="deckValue.brkey"
                :inspectionId="deckValue.inspectionId"
                @updateComment="
                  (comment) =>
                    setUpdatedComment(
                      comment,
                      INSPECTION_COMMENTS.DECK_UNDERSIDE
                    )
                "
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12">
              <CommentTypeTextField
                v-if="moduleType == MODULE_TYPES.INSPECTION"
                label="Deck Drainage"
                id="field_Deck_Drainage"
                :inspectionCommentType="INSPECTION_COMMENTS.DECK_DRAINAGE"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
              />
              <ManagementCommentTypeTextField
                v-else
                label="Deck Drainage"
                id="field_Deck_Drainage"
                :inspectionCommentType="INSPECTION_COMMENTS.DECK_DRAINAGE"
                :readonly="!isEditable"
                :counter="commentsMaxLength"
                :maxlength="commentsMaxLength"
                :comments="deckValue.inspectionComments"
                :brkey="deckValue.brkey"
                :inspectionId="deckValue.inspectionId"
                @updateComment="
                  (comment) =>
                    setUpdatedComment(
                      comment,
                      INSPECTION_COMMENTS.DECK_DRAINAGE
                    )
                "
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>
<script>
import { ref, computed, onMounted, watch } from "vue";
import { useInspectionStore } from "@/stores/inspection";
import { REFERENCE_TABLE } from "@/constants/ReferenceTables";
import { useConfigStore } from "@/stores/config";
import { getHistDuration } from "@/composables/util";
import { ENV_CONFIG_PROPERTY } from "@/constants/EnvConfigProperties";
import { INSPECTION_COMMENTS } from "@/constants/Inspections";
import CommentTypeTextField from "@/components/common/CommentTypeTextField.vue";
import useDateField from "@/composables/dateField.js";
import {
  requiredRule,
  greaterThanZeroRule,
  lessThanOneRule,
  lessThanValueRule,
} from "@/composables/validationRules";
import LabelSelect from "@/components/common/LabelSelect.vue";
import LabelText from "@/components/common/LabelText.vue";
import { ROUNDING_FACTOR } from "@/constants/RoundingFactors";
import { PAIR_CODE } from "@/constants/MetricConversionPairCodes";
import { DECK_MAX_VALUE, MODULE_TYPES } from "@/constants/InspectionConstants";
import LabelDatefield from "@/components/common/LabelDatefield.vue";
import useDeckData from "@/composables/deckData";
import ExpandCollapseToggle from "@/components/shared/ExpandCollapseToggle.vue";
import { useDeckStore } from "@/components/inspection/store/deck";
import { useQuickSearchStore } from "@/stores/quickSearch";
import { useInspectionLinksStore } from "@/stores/inspectionLinks";
import ManagementCommentTypeTextField from "@/components/common/ManagementCommentTypeTextField.vue";

export default {
  name: "InspectionDeck",
  props: {
    moduleType: { default: MODULE_TYPES.INSPECTION },
  },
  setup(props) {
    const allPanels = [0, 1];
    const panel = ref(allPanels);
    const inspectionStore = useInspectionStore();
    const inspectionLinksStore = useInspectionLinksStore();
    let deckConditionUpdated = ref(false);
    let surfaceConditionUpdated = ref(false);
    let conditionUpdated = ref(false);
    let valid = ref(true);
    let inspectionDeckForm = ref(null);
    const deckStore = useDeckStore();
    const quickSearchStore = useQuickSearchStore();
    const structureHeader = computed(() => quickSearchStore.getStructure());
    let deckValue = ref({});
    const { getFormattedDateStringNoTime } = useDateField();
    const configStore = useConfigStore();
    const hasAccessToEdit = computed(() => true);
    let resetDirty = ref(false);

    const isEditable = computed(() => {
      if (props.moduleType == MODULE_TYPES.MANAGEMENT) {
        //TODO add rbac
        return (
          !quickSearchStore.getErrorMessage() &&
          deckValue.value?.BRKEY?.length > 0 &&
          hasAccessToEdit.value
        );
      } else {
        return inspectionStore.isEditable;
      }
    });

    if (!isEditable.value) {
      panel.value = allPanels;
    }

    onMounted(async () => {
      await validate();
      await readData();
    });

    const readData = async () => {
      deckValue.value = {};
      if (
        props.moduleType == MODULE_TYPES.MANAGEMENT &&
        structureHeader.value?.BRKEY
      ) {
        await deckStore.loadDeckObject(
          props.moduleType,
          structureHeader.value.BRKEY,
          structureHeader.value.INSPECTIONID
        );
        deckValue.value = deckStore.getDeckObject(props.moduleType);
        resetDirty.value = true;
      } else if (props.moduleType == MODULE_TYPES.INSPECTION) {
        const structure = computed(() => inspectionStore.selectedInspection);
        await deckStore.loadDeckObject(
          props.moduleType,
          null,
          null,
          structure.value
        );
        deckValue.value = deckStore.getDeckObject(props.moduleType);
        const { getData } = useDeckData();
        deckValue.value.roadwayWidth = getData.value;
        resetDirty.value = true;
      }
    };

    async function validate() {
      await inspectionDeckForm.value.validate();
    }

    const conditionRatings = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.CONDITION_RATING)
    );

    const deckSurfaceTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.DECK_SURFACE_TYPE)
    );

    const deckMembraneTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.DECK_MEMBRANE_TYPE)
    );

    const deckProtectionTypes = computed(() =>
      configStore.getReferenceValues(REFERENCE_TABLE.DECK_PROTECTION_TYPE)
    );

    const commentsMaxLength = computed(() => {
      return configStore.getEnvConfigValue(
        ENV_CONFIG_PROPERTY.INSPECTION_COMMENT_MAX_LENGTH
      );
    });

    const updateDuration = () => {
      if (surfaceConditionUpdated.value) {
        deckValue.value.DECK_WS_SURF_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            deckValue.value.DECK_WS_SURF_DURATION_CHANGES
          );
        deckValue.value.DECK_WS_SURF_DURATION = inspectionStore.setDuration(
          deckValue.value.DECK_WS_SURF_DURATION,
          deckValue.value.DECK_WS_SURF_DURATION_CHANGES
        );
      }

      if (deckConditionUpdated.value) {
        deckValue.value.DK_DURATION_CHANGES =
          inspectionStore.setDurationChanges(
            deckValue.value.DK_DURATION_CHANGES
          );
        deckValue.value.DK_DURATION = inspectionStore.setDuration(
          deckValue.value.DK_DURATION,
          deckValue.value.DK_DURATION_CHANGES
        );
      }

      deckConditionUpdated.value = false;

      surfaceConditionUpdated.value = false;

      conditionUpdated.value = false;
    };

    const updateMainRecordedDate = () => {
      deckValue.value.MAIN_WS_THICK_DATE = new Date();
    };

    const updateApproachRecordedDate = () => {
      deckValue.value.APPR_WS_THICK_DATE = new Date();
    };

    const updateDate = () => {
      deckValue.value.EST_CHLORIDE_CONTENT_DATE = new Date();
    };

    const toggleAllPanels = (expandAll) => {
      if (expandAll) {
        panel.value = allPanels;
      } else {
        panel.value = [];
      }
    };

    const bridgeMedianTypes = computed(() => {
      return configStore.getReferenceValues(
        REFERENCE_TABLE.BRIDGE_MEDIAN,
        null,
        null,
        true
      );
    });

    const isRequiredByGroup = computed(() =>
      ["S1", "L1", "A1"].includes(structureHeader.value?.REPORTGROUP?.trim())
    );

    const saveChanges = async () => {
      const structure = inspectionStore.selectedInspection;
      const isSuccess = await deckStore.saveDeck(
        props.moduleType,
        deckValue.value,
        structure
      );
      if (isSuccess) {
        inspectionLinksStore.isDirty = false;
        await readData(); // Using this flag to not set the dirty flag in watch
        resetDirty.value = true;
      }
      return isSuccess;
    };

    const cancelChanges = async () => {
      //only called from Inspection Links
      deckValue.value = deckStore.getDeckObject(props.moduleType);
      // Using this flag to not set the dirty flag in watch
      resetDirty.value = true;
      inspectionLinksStore.isDirty = false;
    };

    const setUpdatedComment = (comment, commentType) => {
      deckValue.value.inspectionComments.map((c) => {
        if (c.commentType == commentType) {
          c.notes = comment.notes;
          c.updateUserId = comment.updateUserId;
          c.updateDateTime = comment.updateDateTime;
        }
        return c;
      });
    };

    // watch those objects used in component from structure to warn about unsaved changes
    watch(
      deckValue,
      () => {
        // This watch will NOT be triggered during initial onMounted()
        // if (JSON.stringify(oldValue) != "{}") {
        if (props.moduleType === MODULE_TYPES.MANAGEMENT) {
          // for management module
          if (resetDirty.value) {
            inspectionLinksStore.isDirty = false;
            resetDirty.value = false;
          } else {
            inspectionLinksStore.isDirty = true;
          }
        } else if (props.moduleType === MODULE_TYPES.INSPECTION) {
          // for inspection  module
          if (resetDirty.value) {
            inspectionStore.setDirtyFlag(false);
            resetDirty.value = false;
          } else {
            inspectionStore.setDirtyFlag(true);
          }
        }
        // }
      },
      { deep: true }
    );

    watch(
      () => [props],
      () => {
        readData();
      },
      { deep: true }
    );

    return {
      conditionUpdated,
      updateDuration,
      surfaceConditionUpdated,
      deckConditionUpdated,
      panel,
      isEditable,
      deckValue,
      conditionRatings,
      deckSurfaceTypes,
      deckMembraneTypes,
      deckProtectionTypes,
      getHistDuration,
      commentsMaxLength,
      INSPECTION_COMMENTS,
      getFormattedDateStringNoTime,
      configStore,
      REFERENCE_TABLE,
      valid,
      validate,
      inspectionDeckForm,
      requiredRule,
      lessThanOneRule,
      greaterThanZeroRule,
      ROUNDING_FACTOR,
      PAIR_CODE,
      lessThanValueRule,
      DECK_MAX_VALUE,
      updateMainRecordedDate,
      updateApproachRecordedDate,
      updateDate,
      toggleAllPanels,
      bridgeMedianTypes,
      isRequiredByGroup,
      readData,
      saveChanges,
      cancelChanges,
      setUpdatedComment,
      MODULE_TYPES,
    };
  },
  components: {
    CommentTypeTextField,
    LabelSelect,
    LabelText,
    LabelDatefield,
    ExpandCollapseToggle,
    ManagementCommentTypeTextField,
  },
};
</script>
