import { defineStore } from "pinia";
import { STRUCTURE_ENDPOINT } from "@/constants/Endpoints";
import { LOGGER } from "@/util/logger";
import axios from "axios";
import { ref } from "vue";
import router from "@/router";

export const useQuickSearchStore = defineStore("quickSearch", () => {
  let structure = ref({
    BRKEY: "",
    BMSID: "",
    REPORTGROUP: "",
    SERVTYPON: "",
    INSPECTIONID: "",
  });
  let errorMessage = ref(false);

  const getStructure = () => {
    return structure.value;
  };
  const setStructure = (value) => {
    structure.value = value;
  };
  const getErrorMessage = () => {
    return errorMessage.value;
  };
  const setErrorMessage = (value) => {
    errorMessage.value = value;
  };

  const resetStructure = () => {
    structure.value = {
      BRKEY: "",
      BMSID: "",
      REPORTGROUP: "",
      SERVTYPON: "",
      INSPECTIONID: "",
    };
  };

  const redirectUserToStructureSearch = (e, id) => {
    router.push({
      name: "StructureSearch",
      query: { bmsId: id, path: router.currentRoute.value.path },
    });
  };

  const getStructureMetaDataByBMSID = async () => {
    if (structure.value.BMSID) {
      let url = STRUCTURE_ENDPOINT.GET_STRUCTURE_META_DATA_BY_BMSID.replace(
        "{bmsId}",
        structure.value.BMSID
      );
      return await axios
        .get(url)
        .then((response) => {
          structure.value.REPORTGROUP = response.data.data.reportGroup;
          structure.value.BRKEY = response.data.data.brkey;
          structure.value.SERVTYPON = response.data.data.servTypOn;
        })
        .catch((e) => {
          LOGGER.logException(e);
          structure.value.REPORTGROUP = "";
          structure.value.BRKEY = "";
          structure.value.SERVTYPON = "";
          structure.value.INSPECTIONID = "";

          if (e?.response?.status === 412) {
            redirectUserToStructureSearch(e, structure.value.BMSID);
            throw new Error(
              `Multiple BRKEYs found for BMSID-${structure.value.BMSID}`
            );
          } else {
            errorMessage.value = true;
          }
        });
    } else {
      resetStructure();
    }
  };

  const getStructureMetaDataByBRKEY = async () => {
    if (structure.value.BRKEY) {
      let url = STRUCTURE_ENDPOINT.GET_STRUCTURE_META_DATA_BY_BRKEY.replace(
        "{brkey}",
        structure.value.BRKEY
      );
      return await axios
        .get(url)
        .then((response) => {
          structure.value.REPORTGROUP = response.data.data.reportGroup;
          structure.value.BMSID = response.data.data.bmsId;
          structure.value.SERVTYPON = response.data.data.servTypOn;
        })
        .catch((e) => {
          LOGGER.logException(e);
          structure.value.REPORTGROUP = "";
          structure.value.BMSID = "";
          structure.value.SERVTYPON = "";
          structure.value.INSPECTIONID = "";
          errorMessage.value = true;
        });
    } else {
      resetStructure();
    }
  };

  return {
    resetStructure,
    getErrorMessage,
    setErrorMessage,
    getStructureMetaDataByBRKEY,
    getStructureMetaDataByBMSID,
    getStructure,
    setStructure,
  };
});
