import { ref } from "vue";
import { defineStore } from "pinia";
import { USER_ENDPOINT } from "@/constants/Endpoints";
import axios from "axios";
import { LOGGER } from "@/util/logger";

export const useUserPermissionStore = defineStore("userPewrmission", () => {
  let appPermissions = ref([]);
  let cachedBRkeyPermission = ref(new Map());

  /** * Asynchronously retrieves user permission data from a specified endpoint.
   * @async
   * @function getUserPermission
   * @returns {Promise<void>} A promise that resolves when the permission data is retrieved and set.
   * @throws Will log an exception if there is an error during the request.
   */
  const getUserPermission = () => {
    return axios
      .get(USER_ENDPOINT.GET_USER_PERMISSION)
      .then((response) => {
        appPermissions.value = response.data.data;
      })
      .catch((e) => {
        LOGGER.logException(e);
      });
  };

  /**
   * Asynchronously retrieves user permission data for a given BR key from a specified endpoint.
   *
   * @async
   * @function getUserPermissionsForBrkey
   * @param {string} brKey - The BR key for which to retrieve permissions.
   * @returns {Promise<any>} A promise that resolves with the permission data associated with the BR key.
   * @throws Will log an exception if there is an error during the request.
   */
  const getUserPermissionsForBrkey = async (brKey) => {
    if (cachedBRkeyPermission.value.has(brKey)) {
      return cachedBRkeyPermission.value.get(brKey);
    }

    const url = USER_ENDPOINT.GET_USER_PERMISSIONS_FOR_BRKEY.replace(
      "{brkey}",
      brKey
    );

    try {
      const response = await axios.get(url);
      cachedBRkeyPermission.value.set(brKey, response.data.data);
      _refreshCache();
    } catch (e) {
      LOGGER.logException(e);
    }

    return cachedBRkeyPermission.value.get(brKey) ?? {};
  };

  /**
   * Updates the permission property of a structure object based on the BRKEY of its Bridge.
   *
   * @param {Object} structure - The structure object to update.
   * @param {Object} structure.Bridge - The Bridge object within the structure.
   * @param {string} structure.Bridge.BRKEY - The BRKEY of the Bridge.
   * @returns {Promise<Object>} The updated structure object with the new permission property.
   */
  const updateStructurePermission = async (structure) => {
    const brKey = structure.Bridge.BRKEY;
    structure.permission = await getUserPermissionsForBrkey(brKey);
    return structure;
  };

  /**
   * Checks if a user has any of the allowed permissions.
   *
   * @param {Array} allowedPermissions - The list of permissions that are allowed.
   * @param {Array} userPermission - The list of permissions that the user has.
   * @returns {boolean} Returns true if the user has any of the allowed permissions, otherwise false.
   */
  const checkUserPermission = (allowedPermissions, userPermission) => {
    if (!Array.isArray(allowedPermissions) || allowedPermissions.length === 0) {
      return false;
    }

    if (!Array.isArray(userPermission) || userPermission.length === 0) {
      return false;
    }

    return allowedPermissions.some((permission) =>
      userPermission.includes(permission)
    );
  };

  const _refreshCache = (brKey) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      getUserPermissionsForBrkey(brKey);
    }, 300000);
  };

  return {
    appPermissions,
    cachedBRkeyPermission,

    checkUserPermission,
    getUserPermission,
    getUserPermissionsForBrkey,
    updateStructurePermission,
  };
});
