import { defineStore } from "pinia";
import { ref } from "vue";

export const useInspectionLinksStore = defineStore("inspectionLinks", () => {
  let isDirty = ref(false);
  let pageReference = ref(null);
  let isSearching = ref(false);
  let isSaving = ref(false);
  let isPrinting = ref(false);

  return {
    isDirty,
    pageReference,
    isSearching,
    isSaving,
    isPrinting,
  };
});
